import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);


const routerIbotech = new Router({
  mode: "history", // https://router.vuejs.org/api/#mode
  linkActiveClass: "open active",

  routes: [
    {
      path: '/',
      name: 'routerIbotech.home',
      component: () => import('@/containers/IbotechContainer.vue'),
      children: [
        {
          path: "dashboard",
          name: "routerIbotech.home",
          component: () => import('@/views/DashboardIbotech.vue'),
          meta: {
            label: "router.dashboard",
            requiresLogin: true
          }
        },
        {
          path: "vehicle",
          name: "routerIbotech.vehicle",
          component: () => import('@/views/ibotech/vehicle/Vehicle.vue'),
          meta: {
            label: "Fuhrpark",
            requiresLogin: true
          }
        },
        {
          path: "location",
          name: "routerIbotech.location",
          component: () => import('@/views/ibotech/location/Location.vue'),
          meta: {
            label: "routerIbotech.location",
            requiresLogin: true
          }
        },
        {
          path: "qrbooking",
          name: "routerIbotech.qrbooking",
          component: () => import('@/views/ibotech/qrbooking/QrBooking.vue'),
          meta: {
            label: "routerIbotech.qrbooking",
            requiresLogin: true
          },
        },
        {
          path: "records",
          name: "routerIbotech.records",
          component: () => import('@/views/ibotech/records/Records.vue'),
          meta: {
            label: "Einträge",
            requiresLogin: true
          },
        },
        {
          path: "missingRecords",
          name: "routerIbotech.missingRecords",
          component: () => import('@/views/ibotech/records/MissingRecords.vue'),
          meta: {
            label: "Fehlende Scans",
            requiresLogin: true
          },
        },
        // It Works! Please Dont touch it !!!!!!!!!
        // Workaround from Woraround
        {
          path: "signMeasurement",
          name: "routerIbotech.signMeasurement",
          component: () => import('@/views/ibotech/measurement/signMeasurement.vue'),
          meta: {
            label: "signMeasurement",
            requiresLogin: true
          },
        },

        {
          path: "signMeasurementBatch",
          name: "routerIbotech.signMeasurementBatch",
          component: () => import('@/views/ibotech/measurement/signMeasurementBatch.vue'),
          meta: {
            label: "signMeasurementBatch",
            requiresLogin: true
          },
        },
        {
          path: "user",
          meta: { label: "routerIbotech.profile", maintenanceModeCheck: true },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              component: () => import("@/views/users/Profile.vue"),
              props: true,
              name: "routerIbotech.profile"
            }
          ]
        },

        {
          path: '*',
          name: 'NotFound',
          component: () => import('@/views/ibotech/404.vue'),
          meta: {
            requiresLogin: true
          },
        },
        {
          path: '*',
          name: 'RecordLimit',
          component: () => import('@/views/ibotech/RecordLimit.vue'),
          meta: {
            requiresLogin: true
          },
        },


      ]

    },
    {
      path: '/qrGenerate',
      name: 'QrGenerate',
      component: () => import('@/containers/QrGenerate.vue'),
    }
    // Weitere Routen hier...
  ]
});

routerIbotech.beforeEach((to, from, next) => {

  const keycloak = Vue.prototype.$keycloak;

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (keycloak && keycloak.authenticated) {
      next();
    } else {
      const loginUrl = keycloak.createLoginUrl();
      window.location.replace(loginUrl);
    }
  } else {
    next();
  }
});


export default routerIbotech;
