import axios from "axios";
import router from "@/router";

// TypeScript interface
import { Role } from "../interface/RoleInterface";
import routerIbotech from "@/router/routerIbotech";
class IbomadeUserService {
  public static baseUrl: string | undefined = process.env.VUE_APP_USER_MANAGER;

  public async getUserList() {
    const response = await axios(`${IbomadeUserService.baseUrl}/user`, {
      method: "GET",
      // @ts-ignore
      headers: IbomadeUserService.getAuthHeaders()
    }).catch((err) => err.response.data.message);
    if (response.status === 200) {
      return response.data.userList;
    } else {
      return response;
    }
  }
  // User Role
  public async getUserRoles() {
    const response = await axios(`${IbomadeUserService.baseUrl}/role`, {
      method: "GET",
      // @ts-ignore
      headers: IbomadeUserService.getAuthHeaders()
    }).catch((err) => err.response.data.message);
    if (response.status === 200) {
      return response.data.roleList;
    } else {
      return response;
    }
  }

  public async createUserRole(role: Role) {
    try {
      const response = await axios(`${IbomadeUserService.baseUrl}/role`, {
        method: "POST",
        // @ts-ignore
        headers: IbomadeUserService.getAuthHeaders(),
        data: role
      });
      return response;
    } catch (error) {
      return error.response.data.message;
    }
  }
  public async deleteUserRole(id: string) {
    try {
      const response = await axios(`${IbomadeUserService.baseUrl}/role/${id}`, {
        method: "DELETE",
        headers: IbomadeUserService.getAuthHeaders(),
        data: { id: id }
      });
      return response;
    } catch (error) {
      return error.response.data.message;
    }
  }

  public async createUser(user: any) {
    try {
      const response = await axios(`${IbomadeUserService.baseUrl}/user`, {
        method: "POST",
        headers: IbomadeUserService.getAuthHeaders(),
        data: user
      });
      return response.status;
    } catch (error) {
      return error.response.data.message;
    }
  }

  public async getUser(id: any) {
    const response = await axios(`${IbomadeUserService.baseUrl}/user/${id}`, {
      method: "GET",
      headers: IbomadeUserService.getAuthHeaders()
    }).catch((err) => err.response.data.message);
    if (response.status === 200) {
      return response;
    } else {
      return response;
    }
  }
  public async getUserProfile(id: string) {
    console.log("send request to get user by profile by  " + id);
    const response = await axios(`${IbomadeUserService.baseUrl}/user/${id}/profile`, {
      method: "GET",
      headers: IbomadeUserService.getAuthHeaders()
    }).catch((err) => err);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  }

  public async updateUserProfile(id: string, user: object) {
    console.log("send request to get user by profile by  " + id);
    const response = await axios(`${IbomadeUserService.baseUrl}/user/${id}/profile`, {
      method: "PUT",
      headers: IbomadeUserService.getAuthHeaders(),
      data: user
    }).catch((err) => err.response.data.message);
    if (response.status === 200) {
      return response;
    } else {
      return response;
    }
  }
  // Update User Information
  public async updateUserInfo(user: any) {
    try {
      const response = await axios(`${IbomadeUserService.baseUrl}/user/${user.id}`, {
        method: "PUT",
        headers: IbomadeUserService.getAuthHeaders(),
        data: user
      });
      return response;
    } catch (err) {
      console.log(err);
      return err.response.data.message;
    }
  }

  public async deleteUser(id: any) {
    try {
      const response = await axios(`${IbomadeUserService.baseUrl}/user/${id}`, {
        method: "DELETE",
        headers: IbomadeUserService.getAuthHeaders(),
        data: { id: id }
      });

      return response;
    } catch (error) {
      return error.response.data.message;
    }
  }

  public async deleteArchivedProjectUser(userIds: Array<String>) {
    try {
      const response = await axios(`${IbomadeUserService.baseUrl}/user/delete-archived-project-user`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          ...IbomadeUserService.getAuthHeaders()
        },
        data: JSON.stringify({ userIds: userIds })
      });

      return response;
    } catch (error) {
      return error.response.data.message;
    }
  }

  public async deleteUserWithoutProjectAssignment(userIds: Array<String>) {

    try {
      const response = await axios(`${IbomadeUserService.baseUrl}/user/delete-without-project-assignment`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          ...IbomadeUserService.getAuthHeaders()
        },
        data: JSON.stringify({ userIds: userIds })
      });

      return response;
    } catch (error) {
      return error.response.data.message;
    }

  }

  public async resetUser(id: any) {
    try {
      const response = await axios(`${IbomadeUserService.baseUrl}/user/${id}/reset-credentials`, {
        method: "POST",
        headers: IbomadeUserService.getAuthHeaders(),
        data: { id: id }
      });

      return response.status;
    } catch (error) {
      return error.response.data.message;
    }
  }

  public async resetOwnCredentials(id: any) {
    try {
      const response = await axios(`${IbomadeUserService.baseUrl}/user/${id}/reset-own-credentials`, {
        method: "POST",
        headers: IbomadeUserService.getAuthHeaders(),
        data: { id: id }
      });

      return response.status;
    } catch (error) {
      return error.response.data.message;
    }
  }

  public async getUsersStats() {
    const response = await axios(`${IbomadeUserService.baseUrl}/user-sessions`, {
      method: "GET",
      headers: IbomadeUserService.getAuthHeaders()
    });
    return response;
  }

  private static getAuthHeaders() {
    let keycloakToken = "";

    if (router.app && router.app.$keycloak.token) {
      keycloakToken = router.app.$keycloak.token;
    } else if (routerIbotech.app && routerIbotech.app.$keycloak.token) {
      keycloakToken = routerIbotech.app.$keycloak.token;
    } else {
      keycloakToken = ""; // Fallback to an empty string if no token is available
    }

    return {
      // @ts-ignore
      Authorization: "Bearer " + keycloakToken,
      Accept: "application/json"
    };
  }
}

const ibomadeUserService = new IbomadeUserService();
export default ibomadeUserService;
